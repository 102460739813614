import React from 'react';
import { TestimonialsSwiper } from 'src/components/testimonials-swiper/testimonials-swiper';
import { S } from './MainPageTestimonials.styled';

const testimonials = [
  {
    name: 'Ciaran Treanor',
    jobTitle: 'CTO',
    text: `For me, one of the fun things about working at Sonalake is the variety. As we’re in the business of designing and developing enterprise software, we’re fortunate to have clients from sectors as varied as ad tech, insurance, data anonymisation, healthcare, investor comms, security, payments and telecommunications.

Through that, we get to learn a lot about these domains. Also, because of our history in telecoms, we have two products we developed for communication service providers. All of this presents an opportunity for Sonalake designers and engineers to apply their knowledge and develop their craft.`,
    personImageUrl: '/testimonial/ciaran.webp',
  },
  {
    name: 'Katarzyna Szlegel',
    jobTitle: 'Head of Recruitment',
    text: `At Sonalake, we prioritise the candidate experience throughout our three-step recruitment process. First, you'll have a video call with one of our experienced IT recruiters to learn more about our company culture and the role. Next, there’s an opportunity to showcase your skills and expertise with a technical home task, live coding session, or tech interview.

    If you'd like to chat about how we work, we'd be happy to line up a chat with one of our team leads or engineering managers. We aim to provide a positive, informative experience for all candidates, and we’re happy to answer any questions or provide feedback along the way.`,
    personImageUrl: '/testimonial/kasia.webp',
  },
  {
    name: 'Urszula Szczepaniak',
    jobTitle: 'Team Leader',
    text: `Our projects are very important, but so are our people. If you have a problem, need to take time off, or become a new parent and want to spend time with your child, there is the flexibility to do that. That’s why people stay here for a long time. 

    People here are not just employees. They’re valued members of our team. As a team lead, I always try to support my team’s development and help with the areas they want to improve. For me, this is a great company if you want to grow and try new things.`,
    personImageUrl: '/testimonial/urszula.webp',
  },
  {
    name: 'Patrik Homola',
    jobTitle: 'React Developer',
    text: `For me, Sonalake is not only the company I work for, but also a place with a perfect work atmosphere and people who are extraordinarily friendly and helpful. The fact that Sonalake has been a stable part of my professional life for over four years is evidence of my satisfaction with the company. During this time, I have learned a lot, worked with several clients, gained a lot of experience, and throughout this period, there has always been, and still is, a strong sense of support, trust in colleagues, and a truly friendly atmosphere. 
    
    So for me, Sonalake is a company that provides a friendly and open space for growth.`,
    personImageUrl: '/testimonial/patrik.png',
  },
  {
    name: 'Artur Sulkowski',
    jobTitle: 'Engineering Manager',
    text: `Candidates often ask what project they will be working on or what their team will look like, and I need to explain that’s not how we work. We don’t have specific departments or teams taking care of just one thing. We have agile project teams.
    
    Our People Team has our clients’ needs to deliver, but we also balance that with our people’s needs. What kind of environment do they like to work in? What kind of technical skills do they want to learn? What mentorship do they need, and what can we provide? We aim to find a project for them that satisfies our business needs and keeps the person happy.`,
    personImageUrl: '/testimonial/artur.webp',
  },
  {
    name: 'Małgorzata Krzyżaniak',
    jobTitle: 'Project Manager',
    text: `You always get the support you need at Sonalake. We usually work in teams, and you’re in constant communication with a team leader or manager, whether it’s a formal meeting or a chat to see how things are going. You’ll get regular feedback to help you measure your progress.

The company invests in your professional development. Not everyone is a natural in every area. You can learn from the people around you or use your training budget to gain the specialised skills you need to be great at your job. It’s a collaborative environment, so there’s always someone there if you have questions or need help with a problem.`,
    personImageUrl: '/testimonial/malgorzata.webp',
  },
  {
    name: 'Alicja Pudlik',
    jobTitle: 'Team Leader',
    text: `Sonalake provided me with flexibility and opportunities. Whether changing my career path or adopting a hybrid role, I've only had positive responses to my suggestions.

    When I realised that what I care about most in my job is people, leadership was a natural choice and process.
    
     At the start of this path, I was invited to do training and participate in team leader meetings. I could gain knowledge, apply it in workshops, and exchange ideas and approaches with experienced leaders. I was encouraged at each step of my leadership journey.`,
    personImageUrl: '/testimonial/alicja.webp',
  },
  {
    name: 'Mieszko Miłek',
    jobTitle: 'Team Leader',
    text: `Working as a Technical Team Leader in the Operations Team at Sonalake, I am impressed with the DevOps team's commitment to the best practices in our AWS environment. Potential candidates can expect to be supported and welcomed as a developer in the DevOps team and can look forward to a culture that emphasises excellence and innovation.

    Do you want to consult on something or need support from the DevOps stream? Just open a discussion in one of our Slack channels. I highly appreciate the DevOps team at Sonalake.`,
    personImageUrl: '/testimonial/mieszko.webp',
  },
];

const MainPageTestimonials = () => {
  return (
    <S.Container>
      <S.Title>Hear from our team</S.Title>
      <TestimonialsSwiper testimonials={testimonials} slides={2.25} />
    </S.Container>
  );
};

export default MainPageTestimonials;
